import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Link from '../components/link';
import Pagination from '../components/pagination';
import { htmlDecode } from '../Utils';

const RESOURCE_CATEGORIES = [
  'case-study',
  'ebook',
  'publication',
  'videos',
  'webinars',
  'whitepapers',
  'thought-leadership',
  'savings-calculator',
  'checklist',
];

const ResourceCatArchiveTemplate = ({ data, pageContext }) => {
  const seoTitle = data.strapiResourcesPage.seo
    ? data.strapiResourcesPage.seo.seoTitle
    : data.strapiResourcesPage.title;
  const seoDesc = data.strapiResourcesPage.seo
    ? data.strapiResourcesPage.seo.seoDescription
    : '';
  const featuredImageUrl = data.strapiResourcesPage.featuredImage?.formats
    ?.large
    ? data.strapiResourcesPage.featuredImage.formats.large.url
    : data.strapiResourcesPage.featuredImage.url;
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  return (
    <Layout classNames="resources page-template-tpl-resource page-template-tpl-resource-php">
      <Seo title={seoTitle} seoTitle={seoTitle} seoDescription={seoDesc} />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div
            className="col-12 banner-bg-image ph0 small"
            style={{
              backgroundImage: `url(${
                featuredImageUrl?.includes(cdnUrl)
                  ? featuredImageUrl
                  : cdnUrl + featuredImageUrl
              })`,
              opacity: 1,
            }}
          />
          <div className="banner-overlay vert-gradient" />
          <div className="banner-inside aic jcc">
            <div
              className="col-12 col-sm-10 col-md-7 banner-text m-center text-center blue-overlay vert-gradient"
              dangerouslySetInnerHTML={{
                __html: `<h1>${pageContext.name}</h1>`,
              }}
            />
          </div>
        </div>
      </section>
      <section className="content-wrap">
        {pageContext?.categories?.edges &&
          pageContext?.categories?.edges.length > 0 && (
            <div className="container" style={{ zIndex: 101 }}>
              <div
                className="bgw bs1 ph.8 pv.8 subsearch sub56"
                style={{ marginTop: '-8rem' }}
              >
                <div className="row align-items-center">
                  <div className="col-12">
                    <ul className="nav" style={{ whiteSpace: 'nowrap' }}>
                      <li className="nav-item">
                        <Link className="nav-link ft5" href="/resources">
                          All
                        </Link>
                      </li>
                      {pageContext.categories.edges.map(category => (
                        <li className="nav-item ml0">
                          <Link
                            className={`nav-link ${
                              category.node.slug === pageContext.slug
                                ? 'active'
                                : ''
                            } ft5`}
                            href={`/resource-categories/${category.node.slug}`}
                          >
                            {category.node.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className="tease-items-block pv6">
          <div className="container">
            <div className="row jcc">
              {data.allStrapiResource.edges.map(({ node }) => (
                <div className="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4 list-item ph2">
                  <div className="list-content bgw br3 mb4 ofh bs1">
                    {node.featuredImage && (
                      <Link
                        href={`/resource/${node.slug}/`}
                        title={htmlDecode(node.title)}
                        target="_blank"
                        className="dbha"
                      >
                        <div
                          className="list-content_image-wrap brdr1--bottom bcg3"
                          style={{
                            backgroundImage: `url(${
                              node.featuredImage.url.includes(cdnUrl)
                                ? node.featuredImage.url
                                : cdnUrl + node.featuredImage.url
                            })`,
                            opacity: 1,
                          }}
                        />
                      </Link>
                    )}
                    <div className="teaser-text-wrap">
                      {node.resource_categories &&
                        node.resource_categories.length > 0 && (
                          <>
                            {node.resource_categories.map((category, index) => (
                              <a
                                className="cat-btn mb2 mr1"
                                href={`/resource-categories/${category.slug}`}
                              >
                                {category.name}
                              </a>
                            ))}
                          </>
                        )}
                      <Link href={`/resource/${node.slug}/`} target="_blank">
                        <h3 className="teaser-header mb1.8 ">
                          {htmlDecode(node.title)}
                        </h3>
                      </Link>
                      <hr className="small orange mb1.8 ml0" />
                      {node.content && (
                        <p className="copy">
                          {htmlDecode(node.content).substr(0, 80)}&hellip;
                        </p>
                      )}
                      {!node.content && node.customerVideoContent && (
                        <p className="copy">
                          {htmlDecode(node.customerVideoContent).substr(0, 80)}
                          &hellip;
                        </p>
                      )}
                      {!node.content &&
                        !node.customerVideoContent &&
                        node.resourceBanner && (
                          <p className="copy">
                            {htmlDecode(node.resourceBanner.resourceSubTitle)}
                          </p>
                        )}
                      {node.resource_categories[0]?.slug === 'case-study' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Get Case Study
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug === 'ebook' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Get the E-Book
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug === 'publication' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          View Publication
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug ===
                        'thought-leadership' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Learn More
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug === 'videos' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Watch Video
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug === 'webinars' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Watch Webinar
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug === 'whitepapers' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Get Whitepaper
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug === 'checklist' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Get Checklist
                        </Link>
                      )}
                      {node.resource_categories[0]?.slug ===
                        'savings-calculator' && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          Get Savings
                        </Link>
                      )}
                      {!RESOURCE_CATEGORIES.includes(node.resource_categories[0]?.slug) && (
                        <Link
                          className="button uppercase"
                          href={`/resource/${node.slug}/`}
                          target="_blank"
                        >
                          View Resource
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {pageContext.totalPages > 1 && (
          <Pagination
            currentPage={pageContext.currentPage}
            totalPages={pageContext.totalPages}
            slug={`resource-categories/${pageContext.slug}`}
          />
        )}
        {data.strapiResourcesPage.ctaBlock && (
          <div
            className={`cta_block cta-i3 tcw before-${data.strapiResourcesPage.ctaBlock.solidBackgroundColor}`}
          >
            <div className="container">
              <div className="row ph1">
                <div className="col-12 bs1 d-flex cta-gradient-bg">
                  <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center pv8">
                    {data.strapiResourcesPage.ctaBlock.heading && (
                      <h2 className="text-center tcw pv1.2 ph1.2 ft10">
                        {htmlDecode(data.strapiResourcesPage.ctaBlock.heading)}
                      </h2>
                    )}
                    {data.strapiResourcesPage.ctaBlock.content && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data.strapiResourcesPage.ctaBlock.content,
                        }}
                      />
                    )}
                    {data.strapiResourcesPage.ctaBlock.link &&
                      data.strapiResourcesPage.ctaBlock.linkText && (
                        <Link
                          href={data.strapiResourcesPage.ctaBlock.link}
                          target={`${
                            data.strapiResourcesPage.ctaBlock.openNewTab
                              ? '_blank'
                              : '_self'
                          }`}
                          className="button"
                        >
                          {data.strapiResourcesPage.ctaBlock.linkText}
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default ResourceCatArchiveTemplate;

export const query = graphql`
  query ResourceCatArchiveTemplate($slug: String!, $limit: Int!, $skip: Int!) {
    strapiResourcesPage {
      seo {
        excludeFromSitemap
        noFollow
        noIndex
        seoTitle
        seoDescription
      }
      title
      heroContent
      featuredImage {
        url
        formats {
          large {
            url
          }
        }
      }
      ctaBlock {
        solidBackgroundColor
        heading
        content
        link
        linkText
        openNewTab
      }
    }
    allStrapiResource(
      limit: $limit
      skip: $skip
      sort: { fields: createdAt, order: DESC }
      filter: {
        showInArchive: { ne: false }
        resource_categories: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          slug
          title
          featuredImage {
            url
          }
          content
          customerVideoContent
          resourceBanner {
            resourceSubTitle
          }
          resource_categories {
            name
            slug
          }
        }
      }
    }
  }
`;
